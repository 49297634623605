import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
/* eslint-disable fht-eslint-rules/no-restricted-syntax-enhanced */
//basic styles
// eslint-disable-next-line fht-eslint-rules/no-restricted-syntax-enhanced


import "./sentryError";
import "./minimalEnvironment";
import "@/pages/base/stats";
import "./basicStyles"; //面包屑、二维码

import "@/pages/modules/breadcrumb";
import "@/pages/uistandards/qrcode"; //文章

import "@/pages/modules/styles/module.postOperation.scss";
import "@/pages/modules/searchBox";
import "@/pages/modules/topbar";
import "@/pages/modules/foregroundHeader";
import "@/pages/uistandards/UIStandard.fhtNotice";
import "@/pages/modules/companyModal";
import "@/pages/modules/companyPhoneNumberModal";
import { fhtCommonEnv, fhtWebGlobal } from "@/common/fhtGlobalEnv";
import { $$ } from "./dynamicLoader";
import { ApplicationEnvEnum } from "@/apis/model/ApplicationEnvEnum";
import { KnownPageTypeEnum } from "@/apis/model/KnownPageTypeEnum";
$$(function () {
  var isOpeningPhotoswipe = $(".image-photoswipe").length || $(".js-photoswipe-container").length;

  if (isOpeningPhotoswipe) {
    import(
    /* webpackChunkName: "imagePhotoswipe" */
    "@/pages/modules/imagePhotoswipe");
  }

  if ($(".js-collect").length) {
    import(
    /* webpackChunkName: "collection" */
    "@/pages/modules/collection");
  }

  if ($(".js-forward").length) {
    import(
    /* webpackChunkName: "socialShare" */
    "@/pages/modules/socialShare");
  }

  if ($(".js-report").length) {
    import(
    /* webpackChunkName: "report" */
    "@/pages/modules/report");
  }

  if ($(".js-user-hovercard")) {
    import(
    /* webpackChunkName: "hovercard" */
    "@/pages/modules/hovercard");
  } // if($(".js-follow").length){
  //     import(/* webpackChunkName: "socialfollow" */  "@/pages/modules/socialFollow");
  // }

});
console.log("shared.ts loaded!!");
$(function () {
  return __awaiter(void 0, void 0, void 0, function () {
    var installDebug, shouldSkipChat, chatAppInstaller;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!(fhtCommonEnv.AppEnv !== ApplicationEnvEnum.Production)) return [3
          /*break*/
          , 2];
          return [4
          /*yield*/
          , import(
          /* webpackChunkName: "debug" */
          "@/common/debug")];

        case 1:
          installDebug = _a.sent().installDebug;
          installDebug();
          _a.label = 2;

        case 2:
          shouldSkipChat = [KnownPageTypeEnum.Account, KnownPageTypeEnum.EditTemplate].includes(fhtWebGlobal === null || fhtWebGlobal === void 0 ? void 0 : fhtWebGlobal.KnownPageType);
          if (!!shouldSkipChat) return [3
          /*break*/
          , 5];
          return [4
          /*yield*/
          , import(
          /* webpackChunkName: "chatAppInstaller" */
          "@/chatAppInstaller")];

        case 3:
          chatAppInstaller = _a.sent().chatAppInstaller;
          return [4
          /*yield*/
          , chatAppInstaller()];

        case 4:
          _a.sent();

          _a.label = 5;

        case 5:
          return [2
          /*return*/
          ];
      }
    });
  });
});