import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.index-of.js";
//see https://stackoverflow.com/questions/5999118/how-can-i-add-or-update-a-query-string-parameter
export var UrlUtils = {
  RemoveHost: function RemoveHost(url) {
    //courtesy of https://stackoverflow.com/questions/11550790/remove-hostname-and-port-from-url-using-regular-expression
    return url.replace(/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, "/"); // http or https
  },
  GetQueryStringByKey: function GetQueryStringByKey(name, url) {
    //see https://stackoverflow.com/questions/979975/how-to-get-the-value-from-the-get-parameters
    if (!url) url = location.href;
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results === null ? null : results[1];
  },
  UpdateQueryString: function UpdateQueryString(key, value, url) {
    if (!url) url = window.location.href;
    var patt = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi");

    if (patt.test(url)) {
      if (typeof value !== "undefined" && value !== null) return url.replace(patt, "$1" + key + "=" + encodeURIComponent(value.toString()) + "$2$3");else {
        var hash = url.split("#");
        url = hash[0].replace(patt, "$1$3").replace(/(&|\?)$/, "");
        if (typeof hash[1] !== "undefined" && hash[1] !== null) url += "#" + hash[1];
        return url;
      }
    } else {
      if (typeof value !== "undefined" && value !== null) {
        var separator = url.indexOf("?") !== -1 ? "&" : "?";
        var hash = url.split("#");
        url = hash[0] + separator + key + "=" + encodeURIComponent(value.toString());
        if (typeof hash[1] !== "undefined" && hash[1] !== null) url += "#" + hash[1];
        return url;
      } else return url;
    }
  }
};