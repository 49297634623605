import "core-js/modules/es.object.set-prototype-of.js";
import "core-js/modules/es.object.create.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");

    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}(); // FHT Swagger jQuery client V2

/* eslint-disable */


import { BaseApi } from "./api";

var ProductApiApi =
/** @class */
function (_super) {
  __extends(ProductApiApi, _super);

  function ProductApiApi() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.productApiAutoCompleteCmsProducts = function (p) {
      var localVarPath = '/api/products/autoCompleteCmsProducts';

      _this.r(p, ["model.companyId"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiAutoCompleteCustomCategories = function (p) {
      var localVarPath = '/api/products/customCategories/_autocomplete';

      _this.r(p, ["args"]);

      var bodyDict = p["args"];
      var _p = p;
      delete _p["args"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiAutoCompleteMyProducts = function (p) {
      var localVarPath = '/api/products/autoCompleteMyProducts';

      _this.r(p, ["model.page"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiAutoCompleteSystemCategories = function (p) {
      var localVarPath = '/api/products/systemCategories/_autocomplete';

      _this.r(p, ["args"]);

      var bodyDict = p["args"];
      var _p = p;
      delete _p["args"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiAutoCompleteUsedSystemCategories = function (p) {
      var localVarPath = '/api/products/systemCategories/_autocompleteused';

      _this.r(p, ["q"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiBatchCancelTop = function (p) {
      var localVarPath = '/api/products/_batchCancelTop';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiBatchDelete = function (p) {
      var localVarPath = '/api/products/_batchdelete';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiBatchModifyCustomCategory = function (p) {
      var localVarPath = '/api/products/_batchModifyCustomCategory';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiBatchModifySystemCategory = function (p) {
      var localVarPath = '/api/products/_batchModifySystemCategory';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiBatchSetTop = function (p) {
      var localVarPath = '/api/products/_batchSetTop';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiCheckProductTitleUnique = function (p) {
      var localVarPath = '/api/products/_checkTitleUnique';

      _this.r(p, ["title"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiCreateProduct = function (p) {
      var localVarPath = '/api/products/_create';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiCustomCategoryHasProductsCount = function (p) {
      var localVarPath = '/api/products/customCategories/{customCategoryId}/productcount'.replace('{' + 'customCategoryId' + '}', String(p["customCategoryId"]));

      _this.r(p, ["customCategoryId"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiFilterProducts = function (p) {
      var localVarPath = '/api/products/filterProducts';

      _this.r(p, ["q", "args.offset", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiGetAllSystemCategories = function () {
      var localVarPath = '/api/products/allSystemCategories';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.productApiGetCompanyAllCustomCategories = function () {
      var localVarPath = '/api/products/allCustomCategories';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.productApiGetCompanyAllCustomCategoriesCachedAsync = function () {
      var localVarPath = '/api/products/allCustomCategoriesCached';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.productApiGetCustomCategoriesByParentIdAsync = function (p) {
      var localVarPath = '/api/products/customCategories/children';

      _this.r(p, []);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiGetHotProductsAsync = function (p) {
      var localVarPath = '/api/products/hotProducts';

      _this.r(p, ["args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiGetLatestProductsAsync = function (p) {
      var localVarPath = '/api/products/latestProducts';

      _this.r(p, ["args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiGetMyCompanyProductCount = function () {
      var localVarPath = '/api/products/totalCount';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.productApiGetMyCompanyTodayCreateProductCountAsync = function () {
      var localVarPath = '/api/products/todayPublishProductCountCount';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.productApiGetProductForInput = function (p) {
      var localVarPath = '/api/products/_getProductForInput';

      _this.r(p, ["productId"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiGetRecommendProductsAsync = function (p) {
      var localVarPath = '/api/products/recommendProducts';

      _this.r(p, ["args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiGetRelatedProducts = function (p) {
      var localVarPath = '/api/products/relatedProducts';

      _this.r(p, ["args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiGetSimilarProducts = function (p) {
      var localVarPath = '/api/products/{id}/similar'.replace('{' + 'id' + '}', String(p["id"]));

      _this.r(p, ["id", "args.offset", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiGetStatProductIdsAsync = function (p) {
      var localVarPath = '/api/products/_getstatproductids';

      _this.r(p, ["args.limit", "args.searchProductType", "args.companyId"]);

      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiGetSyncFht1Products = function (p) {
      var localVarPath = '/api/products/getSyncFht1Products';

      _this.r(p, ["fht1ProductIds"]);

      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiGetSystemCategoriesByParentId = function (p) {
      var localVarPath = '/api/products/systemCategories/children';

      _this.r(p, []);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiGetUsedCustomCategoriesOfCompanyCachedAsync = function () {
      var localVarPath = '/api/products/allCompanyUsedCustomCategories';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.productApiGetUsedSystemCategoriesOfCompanyCachedAsync = function () {
      var localVarPath = '/api/products/allCompanyUsedSystemCategories';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.productApiHotWordPromotion = function (p) {
      var localVarPath = '/api/products/hotwordpromotions';

      _this.r(p, ["args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiMyProducts = function (p) {
      var localVarPath = '/api/products/myProducts';

      _this.r(p, ["args.orderByType", "args.orderType", "args.currPage", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiProductAutoComplete = function (p) {
      var localVarPath = '/api/products/_autoComplete';

      _this.r(p, ["keyword"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiProductDetail = function (p) {
      var localVarPath = '/api/products/{productId}'.replace('{' + 'productId' + '}', String(p["productId"]));

      _this.r(p, ["productId"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiSearch = function (p) {
      var localVarPath = '/api/ProductApi';

      _this.r(p, ["args.isActiveIndexSort", "args.isOnlyCompanyVerified", "args.isOnlyVipCompany", "args.isMobileUser", "args.limit", "args.currPage"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.productApiUpdateOrder = function (p) {
      var localVarPath = '/api/products/_updateOrder';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.productApiUpdateProduct = function (p) {
      var localVarPath = '/api/products/_update';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    return _this;
  }

  return ProductApiApi;
}(BaseApi);

export { ProductApiApi };