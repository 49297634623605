import { NetworkTypeEnum } from "./NetworkTypeEnum";
export function convertFromNetworkTypeEnum(e) {
  switch (e) {
    case NetworkTypeEnum.Disconnected:
      return 1;

    case NetworkTypeEnum.Wifi:
      return 2;

    case NetworkTypeEnum._2G:
      return 3;

    case NetworkTypeEnum._3G:
      return 4;

    case NetworkTypeEnum._4G:
      return 5;

    case NetworkTypeEnum._5G:
      return 6;

    case NetworkTypeEnum.Cable:
      return 10;

    default:
      throw new Error(e + " is an invalid NetworkTypeEnum");
  }
}