import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { assert } from "@/common/assert";
import { scrollToView } from "@/pages/base/scrollToView";
import { CustomJqueryEvents } from "@/pages/base/customJqueryEvents";
import "@/pages/base/styles/base/UIStandard.scss";
/**
 * 读取fht1.0或者fht2.0的登录状态信息卡
 */
// tslint:disable-next-line: no-any

$(function () {
  $(".expandable-content").each(function (_index, ctx) {
    assert($(".expandable-content__full", ctx).length === 1, ".expandable-content__full not found");
    assert($(".expandable-content__excerpt", ctx).length === 1, ".expandable-content__excerpt not found");
    assert($(".expandable-content__expand", ctx).length === 1, ".expandable-content__expand not found");
    assert($(".expandable-content__collapse", ctx).length === 1, ".expandable-content__collapse not found");
  });
  $(document).on("click", ".expandable-content__expand,.expandable-content__excerpt", function (_e) {
    var $content = $(this).closest(".expandable-content");
    assert($content.length === 1, ".expandable-content not found"); //TODO change to javascript and animate in future.

    $content.addClass("expandable-content--expanded");
  });
  $(document).on("click", ".expandable-content__collapse", function () {
    var $content = $(this).closest(".expandable-content");
    assert($content.length === 1, ".expandable-content not found");
    $content.removeClass("expandable-content--expanded");
    scrollToView($content);
  });
  $(document).on("click", ".expandable-content a", function (e) {
    e.stopPropagation();
  });
});
/*固定URL的$.load封装*/

$(function () {
  var $el = $(".ajax-load");
  $el.each(function () {
    var url = $(this).data("load-url");
    assert(url, "load-url is falsy");
    $(this).hide().load(url, function (_response, status) {
      var error = status === "error" ? new Error("ajax-load failed for url:" + url) : undefined;
      var $this = $(this);
      $el.trigger(CustomJqueryEvents.AjaxLoadedHtmlEvent, error);

      if (!$this.children().length) {
        return;
      } else {
        $this.fadeIn(300);
      }
    });
  });
});
$(function () {
  //icon hover 动画
  $(".js-hover-icon-contain").each(function (index, element) {
    assert($(this).find(".js-hover-icon").length, "js-hover-icon-contain 的子元素icon必须标记js-hover-icon");
  });
  $(document).on("mouseenter mouseleave", ".js-hover-icon-contain", function (ev) {
    var isHovering = ev.type === "mouseenter";
    var $this = $(this);
    $this.find(".js-hover-icon").css({
      transition: "transform .3s ease-in",
      transform: "rotate(" + (isHovering ? 180 : 0) + "deg)"
    });
  });
});