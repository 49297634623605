import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.global-this.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.promise.js";

// ===============================================
// This file is autogenerated - Please do not edit
// ===============================================
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
      m = s && o[s],
      i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function next() {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};
/* eslint-disable */
// appName: FHT.Core.Web
// appVersion: v1
// supportsRuleValidator: 
// supportsClassValidator: 
// supportsCustomValidator: 
// supportsDescribeEnum: true
// supportsConvertToEnum: true
// supportsConvertFromEnum: true
// This file generates to api.ts
// ts client for jquery


export function getValueByDeepPath(obj, path) {
  try {
    for (var i = 0, p = path.split("."), len = p.length; i < len; i++) {
      obj = obj[p[i]];
    }

    return obj;
  } catch (err) {
    return undefined;
  }
}
export var BASE_PATH = function () {
  // process.env.API_BASE_PATH_RUNTIME_JS_EXPR can be any valid Javascript expression that evaluates to a 
  // (1) string, or (2) a function which after being called, returns a string that containing the API BASE PATH URL. e.g. `fhtGlobal.hosts.FhtApiRoot`;
  if (typeof process.env.API_BASE_PATH_RUNTIME_JS_EXPR === "string") {
    var that = typeof window !== "undefined" ? window : globalThis;
    var key = process.env.API_BASE_PATH_RUNTIME_JS_EXPR;
    var val = getValueByDeepPath(that, key);

    if (typeof val === "string") {
      return val;
    }

    if (typeof val === "function") {
      return val();
    }

    if (typeof window !== "undefined") {
      if (key.length) throw new Error("key: " + key + " does not evaluate to a valid value");
    }
  } // if API path is not found in window, we will use from environment variable (which is set when running webpack build)


  if (typeof process.env.API_BASE_PATH === "string") {
    return process.env.API_BASE_PATH;
  }

  return process.env.NODE_ENV === "development" ? "" : "";
}();
export function jsonReviver(key, value) {
  if (typeof value === 'string') {
    if (value.startsWith("20") && /^\d{4}-\d{2}-\d{2}/.test(value)) {
      var d = new Date(value);
      return d;
    }
  }

  return value;
}
export function pad(n) {
  return n < 10 ? "0" + n : n;
}
export function toISOString(d) {
  return d.getUTCFullYear() + "-" + pad(d.getUTCMonth() + 1) + "-" + pad(d.getUTCDate()) + "T" + pad(d.getUTCHours()) + ":" + pad(d.getUTCMinutes()) + ":" + pad(d.getUTCSeconds()) + "Z";
}

function formatParam(p) {
  if (Object.prototype.toString.call(p) === "[object Date]") {
    return toISOString(p);
  }

  return p;
} // courtesy of https://stackoverflow.com/questions/54122326/use-reviver-function-in-jquery-ajax-requests


$.ajaxSetup({
  converters: {
    // default was jQuery.parseJSON
    "text json": function textJson(data) {
      return JSON.parse(data, jsonReviver);
    }
  }
});
export var defaultHeaders = {};
export var globalConfig = {
  //please use webpack's DefinePlugin to define API_PATH_ROOT
  basePath: BASE_PATH,
  extraAjaxSettings: {}
};

var OAuth =
/** @class */
function () {
  function OAuth() {}

  OAuth.prototype.applyToRequest = function (requestOptions) {
    requestOptions.headers = __assign(__assign({}, requestOptions.headers), defaultHeaders);
  };

  return OAuth;
}();

export { OAuth };

var BaseApi =
/** @class */
function () {
  function BaseApi() {
    this.basePath = globalConfig.basePath;
    this.defaultHeaders = {};
    this.auth = new OAuth();
  }
  /** Assert required */


  BaseApi.prototype.r = function (params, fieldNames) {
    var e_1, _a;

    try {
      for (var fieldNames_1 = __values(fieldNames), fieldNames_1_1 = fieldNames_1.next(); !fieldNames_1_1.done; fieldNames_1_1 = fieldNames_1.next()) {
        var fieldName = fieldNames_1_1.value;
        var value = params[fieldName];

        if (value === null || value === undefined) {
          throw new Error("field: " + fieldName + " required");
        }
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (fieldNames_1_1 && !fieldNames_1_1.done && (_a = fieldNames_1["return"])) _a.call(fieldNames_1);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
  };

  BaseApi.prototype.ajax = function (method, localVarPath, queryParameters, bodyDict) {
    var e_2, _a;

    var url = this.basePath + localVarPath;
    var queryParams = {};

    try {
      for (var _b = __values(Object.entries(queryParameters)), _c = _b.next(); !_c.done; _c = _b.next()) {
        var _d = __read(_c.value, 2),
            key = _d[0],
            val = _d[1];

        if (typeof val !== "undefined") {
          queryParams[key] = formatParam(val);
        }
      }
    } catch (e_2_1) {
      e_2 = {
        error: e_2_1
      };
    } finally {
      try {
        if (_c && !_c.done && (_a = _b["return"])) _a.call(_b);
      } finally {
        if (e_2) throw e_2.error;
      }
    }

    if (Object.keys(queryParams).length) {
      url = url + "?" + $.param(queryParams);
    }

    var requestOptions = __assign(__assign({}, globalConfig.extraAjaxSettings), {
      url: url,
      type: method,
      headers: this.defaultHeaders,
      processData: false
    });

    if (bodyDict && Object.keys(bodyDict).length) {
      requestOptions.data = JSON.stringify(bodyDict);
      requestOptions.contentType = "application/json; charset=utf-8";
    }

    this.auth.applyToRequest(requestOptions);
    return new Promise(function (resolve, reject) {
      $.ajax(requestOptions).then(function (data, textStatus, jqXHR) {
        return resolve(data);
      }, function (xhr, textStatus, errorThrown) {
        return reject(xhr);
      });
    });
  };

  return BaseApi;
}();

export { BaseApi };