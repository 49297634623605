import { SearchTypeEnum } from "./SearchTypeEnum";
export function describeSearchTypeEnum(e) {
  switch (e) {
    case SearchTypeEnum.Product:
      return "产品";

    case SearchTypeEnum.TopicPost:
      return "服务";

    case SearchTypeEnum.User:
      return "用户";

    case SearchTypeEnum.Company:
      return "公司";

    case SearchTypeEnum.Meetup:
      return "一起玩";

    default:
      throw new Error(e + " is an invalid SearchTypeEnum");
  }
}