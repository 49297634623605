import { ApplicationEnvEnum } from "@/apis/model/ApplicationEnvEnum";
import { KnownPageTypeEnum } from "@/apis/model/KnownPageTypeEnum";
import { assert } from "./assert";
var debugHosts = {
  FhtWebTopicRoot: "http://topic.mobiledevelopmentfht360web.com",
  FhtWebDetailRoot: "http://detail.mobiledevelopmentfht360web.com",
  FhtWebCompanyRoot: "http://company.mobiledevelopmentfht360web.com",
  FhtWebMobileCompanyRoot: "http://wap.mobiledevelopmentfht360web.com",
  FhtWebUrlRoot: "http://www.mobiledevelopmentfht360web.com",
  FhtWebMobileUrlRoot: "http://m.mobiledevelopmentfht360web.com",
  FhtApiRoot: "http://192.168.1.223:8000"
};
var commonEnv = {
  AppEnv: ApplicationEnvEnum.MobileDevelopment,
  AppVersion: "0.0.0",
  DEBUG: 1,
  IsRelease: false,
  Hosts: debugHosts
};
var debugFhtGlobal = {
  Env: commonEnv,
  KnownPageType: KnownPageTypeEnum.Home
};
export var IS_MAIN_WEB = Boolean(window.fhtGlobal);
export var IS_CMS_WEB = Boolean(window.cmsGlobal);
assert(IS_MAIN_WEB !== IS_CMS_WEB, "either main or cms");
export var fhtCommonEnv = function () {
  if (IS_MAIN_WEB) {
    var g = window.fhtGlobal;
    assert(g.Env, "g.Env is falsy for main web");
    return g.Env;
  } else if (IS_CMS_WEB) {
    var g = window.cmsGlobal;
    assert(g.Env, "g.Env is falsy for cms web");
    assert(g.Cms, "g.Cms is falsy for cms web");
    return g.Env;
  } else {
    throw new Error("unknown web env");
  }
}();
assert(fhtCommonEnv.AppEnv, "appEnv gotta be defined");
export var fhtWebGlobal = function () {
  var g = window.fhtGlobal;

  if (process.env.CRA) {
    return debugFhtGlobal;
  }

  return g;
}();
export function isKnownPage(page) {
  return fhtWebGlobal && fhtWebGlobal.KnownPageType === page;
}
export var currentCmsCompanyId = function () {
  if (IS_CMS_WEB) {
    var g = window.cmsGlobal;
    assert(g.Cms, "g.Cms falsy");
    return g.Cms.CompanyId;
  }

  return;
}();
export var isPreviewingCms = function () {
  var _a;

  var g = window.cmsGlobal;
  return Boolean((_a = g === null || g === void 0 ? void 0 : g.Cms) === null || _a === void 0 ? void 0 : _a.IsPreviewing);
}();
export var isOnPremisesCms = function () {
  var _a;

  var g = window.cmsGlobal;
  return Boolean((_a = g === null || g === void 0 ? void 0 : g.Cms) === null || _a === void 0 ? void 0 : _a.IsOnPremises);
}();
export var QRCODE_PATH = function () {
  return "https://img2.fht360.com/2021/3/19/14/ec03f27ed4fe466dbbd582194937ac1f-800x800.png@!large";
}();
export var IS_DEV = process.env.NODE_ENV === "development";
export var IS_CRA = Boolean(process.env.CRA); //create-react-app

export var IS_PROD = fhtCommonEnv.AppEnv === ApplicationEnvEnum.Production;
export var DEBUG_LOG_ERROR = Boolean(localStorage.getItem("DEBUG_LOG_ERROR"));
var IS_PROD_OR_STAGING = fhtCommonEnv.AppEnv === ApplicationEnvEnum.Staging || IS_PROD;
var IS_PROD_OR_STAGING_OR_MOBILEDEVELOPMENT = fhtCommonEnv.AppEnv === ApplicationEnvEnum.MobileDevelopment || IS_PROD_OR_STAGING;
export var LOG_ERROR_ENABLED = fhtCommonEnv.IsRelease && IS_PROD_OR_STAGING_OR_MOBILEDEVELOPMENT || DEBUG_LOG_ERROR;