import { UserActivityTypeEnum } from "./UserActivityTypeEnum";
export function convertFromUserActivityTypeEnum(e) {
  switch (e) {
    case UserActivityTypeEnum.EnterApp:
      return 101;

    case UserActivityTypeEnum.Register:
      return 102;

    case UserActivityTypeEnum.Login:
      return 103;

    case UserActivityTypeEnum.CreateCompany:
      return 111;

    case UserActivityTypeEnum.Create:
      return 121;

    case UserActivityTypeEnum.Update:
      return 122;

    case UserActivityTypeEnum.ViewDetail:
      return 201;

    case UserActivityTypeEnum.Impression:
      return 202;

    case UserActivityTypeEnum.ViewList:
      return 203;

    case UserActivityTypeEnum.Search:
      return 204;

    case UserActivityTypeEnum.Hover:
      return 205;

    case UserActivityTypeEnum.Click:
      return 206;

    case UserActivityTypeEnum.Collect:
      return 301;

    case UserActivityTypeEnum.Report:
      return 302;

    case UserActivityTypeEnum.Chat:
      return 303;

    case UserActivityTypeEnum.Forward:
      return 304;

    case UserActivityTypeEnum.FriendRequest:
      return 311;

    case UserActivityTypeEnum.AcceptFriend:
      return 312;

    case UserActivityTypeEnum.RejectFriend:
      return 313;

    case UserActivityTypeEnum.Error:
      return 400;

    case UserActivityTypeEnum.AuthenticateCompany:
      return 512;

    case UserActivityTypeEnum.EnableShop:
      return 513;

    case UserActivityTypeEnum.CompanyManagement:
      return 514;

    case UserActivityTypeEnum.JoinCompany:
      return 515;

    case UserActivityTypeEnum.SwitchTemplate:
      return 516;

    default:
      throw new Error(e + " is an invalid UserActivityTypeEnum");
  }
}