// FHT Swagger jQuery client V2

/**
 * FHT.Core.Web.Models.KnownPageType, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */

/**
* FHT.Core.Web.Models.KnownPageType, FHT.Core.Web, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export var KnownPageTypeEnum;

(function (KnownPageTypeEnum) {
  KnownPageTypeEnum["Home"] = "Home";
  KnownPageTypeEnum["ProductList"] = "ProductList";
  KnownPageTypeEnum["ProductDetail"] = "ProductDetail";
  KnownPageTypeEnum["TopicPostList"] = "TopicPostList";
  KnownPageTypeEnum["TopicPostDetail"] = "TopicPostDetail";
  KnownPageTypeEnum["UserDetail"] = "UserDetail";
  KnownPageTypeEnum["Account"] = "Account";
  KnownPageTypeEnum["OfficialSite"] = "OfficialSite";
  KnownPageTypeEnum["App"] = "App";
  KnownPageTypeEnum["Membership"] = "Membership";
  KnownPageTypeEnum["EditTemplate"] = "EditTemplate";
  KnownPageTypeEnum["Center"] = "Center";
  KnownPageTypeEnum["ProductSearch"] = "ProductSearch";
  KnownPageTypeEnum["TopicPostSearch"] = "TopicPostSearch";
  KnownPageTypeEnum["UserSearch"] = "UserSearch";
  KnownPageTypeEnum["CompanySearch"] = "CompanySearch";
})(KnownPageTypeEnum || (KnownPageTypeEnum = {}));