// FHT Swagger jQuery client V2

/**
 * FHT.ApplicationEnv, FHT.ApplicationEnvironment, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */

/**
* FHT.ApplicationEnv, FHT.ApplicationEnvironment, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export var ApplicationEnvEnum;

(function (ApplicationEnvEnum) {
  ApplicationEnvEnum["Local"] = "Local";
  ApplicationEnvEnum["Development"] = "Development";
  ApplicationEnvEnum["MobileDevelopment"] = "MobileDevelopment";
  ApplicationEnvEnum["Staging"] = "Staging";
  ApplicationEnvEnum["Production"] = "Production";
})(ApplicationEnvEnum || (ApplicationEnvEnum = {}));