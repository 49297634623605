import { assert } from "@/common/assert";
$.fn.extend({
  clickOutside: function clickOutside(handler) {
    assert(handler, "handler falsy");
    this.each(function (_index, el) {
      $(document.body).click(function (e) {
        var clickedInsideEl = $(el).is(e.target) || $.contains(el, e.target);

        if (!clickedInsideEl) {
          handler.call(el, e);
        }
      });
    });
  }
});
export function createClickOutside($el, handler) {
  $el.clickOutside(handler);
}