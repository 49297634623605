// FHT Swagger jQuery client V2

/**
 * FHT.Common.Types.SearchType, FHT.Common.Types, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 */

/**
* FHT.Common.Types.SearchType, FHT.Common.Types, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export var SearchTypeEnum;

(function (SearchTypeEnum) {
  SearchTypeEnum["Product"] = "Product";
  SearchTypeEnum["TopicPost"] = "TopicPost";
  SearchTypeEnum["User"] = "User";
  SearchTypeEnum["Company"] = "Company";
  SearchTypeEnum["Meetup"] = "Meetup";
})(SearchTypeEnum || (SearchTypeEnum = {}));