import { debounce } from "./asyncUtils";

var BatchProcessor =
/** @class */
function () {
  function BatchProcessor(batchCallback, windowMs) {
    var _this = this;

    if (windowMs === void 0) {
      windowMs = 1000;
    }

    this.windowMs = windowMs;
    this.buffer = [];
    this.debouncedBatchInvoke = debounce(windowMs, function () {
      if (!_this.buffer.length) return;
      batchCallback(_this.buffer);
      _this.buffer = [];
    });
  }

  BatchProcessor.prototype.queue = function (item) {
    this.buffer.push(item);
    this.debouncedBatchInvoke();
  };

  return BatchProcessor;
}();

export { BatchProcessor };