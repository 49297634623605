import "core-js/modules/es.object.set-prototype-of.js";
import "core-js/modules/es.object.create.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");

    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}(); // FHT Swagger jQuery client V2

/* eslint-disable */


import { BaseApi } from "./api";

var TopicPostApiApi =
/** @class */
function (_super) {
  __extends(TopicPostApiApi, _super);

  function TopicPostApiApi() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.topicPostApiAutoCompleteTopicPosts = function (p) {
      var localVarPath = '/api/topicposts/_autoCompleteTopicPosts';

      _this.r(p, []);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiBatchDelete = function (p) {
      var localVarPath = '/api/topicposts/_batchdelete';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.topicPostApiCheckTitleUnique = function (p) {
      var localVarPath = '/api/topicposts/_checkTitleUnique';

      _this.r(p, ["title"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiCreateTopicPost = function (p) {
      var localVarPath = '/api/topicposts/_create';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.topicPostApiDetail = function (p) {
      var localVarPath = '/api/topicposts/{topicpostId}'.replace('{' + 'topicpostId' + '}', String(p["topicpostId"]));

      _this.r(p, ["topicpostId"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiGet = function (p) {
      var localVarPath = '/api/topicposts/_get';

      _this.r(p, ["topicPostId"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiGetHotTopicPosts = function (p) {
      var localVarPath = '/api/topicposts/hotTopicPosts';

      _this.r(p, ["args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiGetLatestUserTopicPostAsync = function (p) {
      var localVarPath = '/api/topicposts/latestTopicPosts';

      _this.r(p, ["args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiGetMyTopicPostsCount = function () {
      var localVarPath = '/api/topicposts/totalCount';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.topicPostApiGetRecentTopicPostsOfCompany = function (p) {
      var localVarPath = '/api/topicposts/recentTopicPostsOfCompany';

      _this.r(p, ["myCompanyId", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiGetRecommendedTopicPosts = function (p) {
      var localVarPath = '/api/topicposts/recommendedTopicPosts';

      _this.r(p, ["args.currPage", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiGetRelatedTopicPosts = function (p) {
      var localVarPath = '/api/topicposts/relatedTopicPosts';

      _this.r(p, ["q", "args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiGetUserTopicPosts = function (p) {
      var localVarPath = '/api/topicposts/userTopicPosts';

      _this.r(p, ["userId", "q", "args.offset", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiHotSearchWords = function (p) {
      var localVarPath = '/api/topicposts/hotSearchWords';

      _this.r(p, ["hotSearchWordType", "size"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiMyTopicPosts = function (p) {
      var localVarPath = '/api/topicposts/myTopicPosts';

      _this.r(p, ["args.orderType", "args.currPage", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiNearBusinessOpportunities = function (p) {
      var localVarPath = '/api/topicposts/_nearBusinessOpportunities';

      _this.r(p, ["args.offset", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiNearBusinessOpportunitiesCount = function (p) {
      var localVarPath = '/api/topicposts/_nearBusinessOpportunitiesCount';

      _this.r(p, []);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiSearch = function (p) {
      var localVarPath = '/api/topicposts/_search';

      _this.r(p, ["args.isActiveIndexSort", "args.hasOnlyCompany", "args.isMobileUser", "args.limit", "args.currPage"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.topicPostApiSelectTypes = function () {
      var localVarPath = '/api/topicposts/selectTypes';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.topicPostApiTopicTypes = function () {
      var localVarPath = '/api/topicposts/topictypes';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.topicPostApiTopicpostTypes = function () {
      var localVarPath = '/api/topicposts/topicpostTypes';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.topicPostApiUpdateTopicPost = function (p) {
      var localVarPath = '/api/topicposts/_update';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    return _this;
  }

  return TopicPostApiApi;
}(BaseApi);

export { TopicPostApiApi };