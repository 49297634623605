import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.trim.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import "@/pages/modules/companyModal/index.scss";
import { assert } from "@/common/assert";
import { UrlHelperExtensions } from "@/url_helpers";
import { ensureAttributesForSelector } from "@/pages/base/utils";
import jqueryDataUtil from "@/common/jqueryDataUtil";
$(function () {
  function processIntroduceContent() {
    var $introduceContent = $(".company-modal__introduce-content");

    if ($introduceContent.length > 0) {
      var text_1 = $introduceContent.text();
      var showText_1 = "";
      var MAX_CHAR_COUNT = 115;

      if (text_1.length > MAX_CHAR_COUNT) {
        var foldhtml_1 = "<span class=\"company-modal__ellipsis\">...<span><a class=\"company-modal__show-all\" href=\"javascript:void(0);\"><span>\u663E\u793A\u5168\u90E8</span>\n                <i class=\"fa fa-angle-down\"></i></a>";
        var unfoldhtml_1 = "<a class=\"company-modal__show-all\" href=\"javascript:void(0);\"><span>\u6536\u8D77</span>\n                <i class=\"fa fa-angle-up\"></i></a>";
        showText_1 = text_1.substring(0, MAX_CHAR_COUNT);
        $introduceContent.html(showText_1 + foldhtml_1);
        $(document).on("click", ".company-modal__show-all", function () {
          var $this = $(this);
          var currentText = $.trim($this.text());

          if (currentText === "显示全部") {
            $introduceContent.html(text_1 + unfoldhtml_1);
          } else {
            $introduceContent.html(showText_1 + foldhtml_1);
          }
        });
      }
    }
  } //加载公司详情


  function loadCompanyCard(companyId) {
    var _this = this;

    assert(companyId, "companyId is falsy");
    $.get(UrlHelperExtensions.CompanyDetailModalUrl(companyId)).done(function (html) {
      return __awaiter(_this, void 0, void 0, function () {
        var createRemodal, companyCard;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              $(document.body).after(html);
              processIntroduceContent();
              return [4
              /*yield*/
              , import("@/pages/uistandards/qrcode")];

            case 1:
              _a.sent();

              return [4
              /*yield*/
              , import("@/pages/vendors/remodal")];

            case 2:
              createRemodal = _a.sent().createRemodal;
              companyCard = createRemodal($('[data-remodal-id="companyDetail-modal"]'));
              companyCard.open();
              $(document).on("click", ".js-huoliao-btn", function () {
                companyCard.close();
              });
              return [2
              /*return*/
              ];
          }
        });
      });
    });
  }

  ensureAttributesForSelector(".js-no-shop", ["data-companyid"]); // 通过企业名称进入企业的a标签改为js跳转

  $(document).on("click", ".js-no-shop", function () {
    var $this = $(this);
    var companyid = jqueryDataUtil.requiredNumber($this, "companyid");
    assert(companyid > 0, "companyid is not positive?");
    loadCompanyCard(companyid);
    return false;
  });
});