import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import "@/pages/modules/companyModal/index.scss";
import { assert } from "@/common/assert";
import { ensureAttributesForSelector } from "@/pages/base/utils";
import jqueryDataUtil from "@/common/jqueryDataUtil";
import { CompanyApiApi } from "@/apis/api/CompanyApiApi";
import "./index.scss";
$(function () {
  //获取公司电话号码
  function getCompanyPhoneNumber(companyId) {
    return __awaiter(this, void 0, Promise, function () {
      var companyApi, companyInfo, encryptionMobile, model, res, err_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            assert(companyId, "companyId is falsy");
            companyApi = new CompanyApiApi();
            return [4
            /*yield*/
            , companyApi.companyApiGetCompany({
              companyId: companyId
            })];

          case 1:
            companyInfo = _a.sent();
            encryptionMobile = companyInfo.CompanyContactInfo.EncryptionMobile;
            if (!encryptionMobile) return [3
            /*break*/
            , 5];
            _a.label = 2;

          case 2:
            _a.trys.push([2, 4,, 5]);

            model = {
              EncryptionText: encryptionMobile
            };
            return [4
            /*yield*/
            , companyApi.companyApiGetPhoneNumber({
              model: model
            })];

          case 3:
            res = _a.sent();
            return [2
            /*return*/
            , res.Str];

          case 4:
            err_1 = _a.sent();
            console.error(err_1);
            return [2
            /*return*/
            , undefined];

          case 5:
            return [2
            /*return*/
            ];
        }
      });
    });
  } // 弹窗中显示


  var $getNumber = $(".js-get-company-phone-number");
  assert($getNumber, "get number button falsy");
  $(document.body).on("click", ".js-get-company-phone-number", function () {
    return __awaiter(this, void 0, void 0, function () {
      var companyId, phoneNumber, $this, createRemodal, companyNumberModal;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ensureAttributesForSelector(".js-get-company-phone-number", ["data-company-id"]);
            companyId = jqueryDataUtil.requiredNumber($getNumber, "company-id");
            return [4
            /*yield*/
            , getCompanyPhoneNumber(companyId)];

          case 1:
            phoneNumber = _a.sent();
            if (!phoneNumber) return [3
            /*break*/
            , 3];
            $this = $(".company-phone-number-modal__val");
            assert($this, "company phone number val falsy");
            $this.get(0).innerHTML = phoneNumber;
            return [4
            /*yield*/
            , import("@/pages/vendors/remodal")];

          case 2:
            createRemodal = _a.sent().createRemodal;
            companyNumberModal = createRemodal($('[data-remodal-id="company-phone-number-modal"]'));
            companyNumberModal.open();
            _a.label = 3;

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  });
});