import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "./index.scss";
import { CustomJqueryEvents } from "@/pages/base/customJqueryEvents";
import { debounce } from "@/pages/base/asyncUtils";
$(function () {
  //masthead-front scroll hover
  function fixSearchBox() {
    var $mastheadfront = $(".masthead-front");

    if (!$mastheadfront.hasClass("masthead-front--fixed")) {
      $mastheadfront.addClass("masthead-front--fixed");
      $(".fht-header__empty").show(0);
      $(".masthead-front--inside. masthead-front--fixed").css({
        transform: "translateY(119px)",
        opacity: 1
      });
      $mastheadfront.find(".fht-qrcode,.index-header-search__keywords").addClass("u--hidden");
      $mastheadfront.find(".search-box").addClass("search-box--hang");
      /* eslint-disable-next-line fht-eslint-rules/jquery-argument-validator */

      $mastheadfront.find(".freerelease__topicpost").addClass("index-header-search__create-topicpost");
      $mastheadfront.find(".index-header-search__container").addClass("index-header-search__container-hang");
      $(".search-box__input").trigger(CustomJqueryEvents.TopbarFixedEvent);
    }
  }

  function unfixSearchBox() {
    var $mastheadfront = $(".masthead-front");

    if ($mastheadfront.hasClass("masthead-front--fixed")) {
      $(".fht-header__empty").hide(0);
      $mastheadfront.css({
        transform: "translateY(0)"
      });
      $mastheadfront.removeClass("masthead-front--fixed");
      $mastheadfront.find(".fht-qrcode,.index-header-search__keywords").removeClass("u--hidden");
      $mastheadfront.find(".search-box").removeClass("search-box--hang");
      /* eslint-disable-next-line fht-eslint-rules/jquery-argument-validator */

      $mastheadfront.find(".freerelease__topicpost").removeClass("index-header-search__create-topicpost");
      $mastheadfront.find(".index-header-search__container").removeClass("index-header-search__container-hang");
      $(".search-box__input").trigger(CustomJqueryEvents.TopbarUnfixedEvent);
    }
  }

  $(document).on("scroll", debounce(10, function (e) {
    var top = $(e.currentTarget).scrollTop();

    if (top > 150) {
      fixSearchBox();
    } else {
      unfixSearchBox();
    }
  }));
});