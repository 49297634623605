import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.date.to-iso-string.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.array.some.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import { fhtStorage } from "./fhtStorage";
import { UrlUtils } from "./urlUtils";
var USER_WEB_SESSION_COUNT = "user_web_session_count";
var USER_WEB_SESSION_LAST_ACTIVE = "user_web_session_last_active";
var USER_WEB_SESSION_UTM_CAMPAIGN = "user_web_session_utm_campaign";

var WebSessionCounter =
/** @class */
function () {
  function WebSessionCounter() {
    this.update();
  }

  WebSessionCounter.prototype.getCount = function () {
    return __awaiter(this, void 0, Promise, function () {
      var val;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , fhtStorage.getItem(USER_WEB_SESSION_COUNT)];

          case 1:
            val = _a.sent();
            return [2
            /*return*/
            , Number(val) || 0];
        }
      });
    });
  };

  WebSessionCounter.prototype.setCount = function (val) {
    return __awaiter(this, void 0, Promise, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , fhtStorage.setItem(USER_WEB_SESSION_COUNT, val.toString())];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  WebSessionCounter.prototype.getLastActive = function () {
    return __awaiter(this, void 0, Promise, function () {
      var time;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , fhtStorage.getItem(USER_WEB_SESSION_LAST_ACTIVE)];

          case 1:
            time = _a.sent();

            if (time) {
              return [2
              /*return*/
              , new Date(time)];
            } else {
              return [2
              /*return*/
              , new Date()];
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  WebSessionCounter.prototype.setLastActive = function (time) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , fhtStorage.setItem(USER_WEB_SESSION_LAST_ACTIVE, time.toISOString())];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  WebSessionCounter.prototype.getLastUtmCampaign = function () {
    return __awaiter(this, void 0, Promise, function () {
      var val;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , fhtStorage.getItem(USER_WEB_SESSION_UTM_CAMPAIGN)];

          case 1:
            val = _a.sent();
            return [2
            /*return*/
            , val || null];
        }
      });
    });
  };

  WebSessionCounter.prototype.setLastUtmCampaign = function (val) {
    return __awaiter(this, void 0, Promise, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , fhtStorage.setItem(USER_WEB_SESSION_UTM_CAMPAIGN, val || "")];

          case 1:
            _a.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  Object.defineProperty(WebSessionCounter.prototype, "currentUtmCampaign", {
    get: function get() {
      return UrlUtils.GetQueryStringByKey("utm_campaign");
    },
    enumerable: false,
    configurable: true
  });

  WebSessionCounter.prototype.update = function () {
    return __awaiter(this, void 0, Promise, function () {
      var count, time;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.getCount()];

          case 1:
            count = _a.sent();
            return [4
            /*yield*/
            , this.getLastActive()];

          case 2:
            time = _a.sent();

            if (count === 0 || this.isNewSession()) {
              this.setCount(count + 1);
              this.setLastActive(new Date());
              this.setLastUtmCampaign(this.currentUtmCampaign);
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  WebSessionCounter.prototype.isNewSession = function () {
    return __awaiter(this, void 0, Promise, function () {
      var time, now, lastUtmCampaign;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.getLastActive()];

          case 1:
            time = _a.sent();
            now = new Date();
            return [4
            /*yield*/
            , this.getLastUtmCampaign()];

          case 2:
            lastUtmCampaign = _a.sent();
            return [2
            /*return*/
            , [(now.getTime() - time.getTime()) / 1000 / 60 > 30, now.toDateString() !== time.toDateString(), lastUtmCampaign !== this.currentUtmCampaign].some(function (b) {
              return b;
            })];
        }
      });
    });
  };

  return WebSessionCounter;
}();

export default new WebSessionCounter();