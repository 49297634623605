import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { assert } from "@/common/assert";
import "jquery.qrcode";
import { createLiveQuery } from "@/pages/vendors/vendor.livequery";
import jqueryDataUtil from "@/common/jqueryDataUtil";
import { ensureAttributesForSelector } from "@/pages/base/utils";
$(function () {
  "use strict";

  ensureAttributesForSelector(".qrcode", ["data-qrcode-url", "data-qrcode-size"]);
  createLiveQuery($(".qrcode"), function () {
    var $this = $(this);
    var qrCodeUrl = jqueryDataUtil.requiredString($this, "qrcode-url");
    var qrCodeSize = jqueryDataUtil.requiredNumber($this, "qrcode-size");
    var foreground = jqueryDataUtil.optionalString($this, "qrcode-foreground") || "#000000";
    if ($this.data("initialized")) return;

    if ($this.find("img").length === 0) {
      assert(qrCodeUrl, "data-qrcode-url not defined for .qrcode");
      assert(qrCodeSize, "data-qrcode-size not defined for .qrcode");
      $(this).qrcode({
        // render: "table",
        text: qrCodeUrl,
        width: qrCodeSize,
        height: qrCodeSize,
        foreground: foreground
      });
      $this.data("initialized", true);
    }
  });
});