export function scrollToView(elements, completeCallback) {
  //see https://stackoverflow.com/questions/6215779/scroll-if-element-is-not-visible
  var $elements = $(elements);

  if ($elements.length) {
    var firstElement = $elements.get(0); //If browser natively support so

    var block = $elements.height() > 0.8 * $(window).height() ? "start" : "center";

    if (firstElement.scrollIntoView) {
      // console.log("scrolling to view", block, elements);
      firstElement.scrollIntoView({
        behavior: "smooth",
        block: block,
        inline: "nearest"
      });
      return;
    }
  }
  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */


  var offset = $($elements).offset().top;

  if (!$elements.is(":visible")) {
    var $firstElement = $elements.first();
    var originalVisibility = $firstElement.css("visibility");
    var originalDisplay = $firstElement.css("display");
    $firstElement.css({
      visibility: "hidden"
    }).show();
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */

    offset = $firstElement.offset().top;
    $firstElement.css({
      visibility: originalVisibility,
      display: originalDisplay
    });
  }

  var visibleAreaStart = $(window).scrollTop();
  var visibleAreaEnd = visibleAreaStart + $(window).height();

  if (offset < visibleAreaStart || offset > visibleAreaEnd) {
    // Not in view so scroll to it
    $("html,body").animate({
      scrollTop: offset - $(window).height() / 3
    }, 1000, "swing", completeCallback || function () {
      console.warn("animation completed");
    });
    return false;
  }

  return true;
}