// FHT Swagger jQuery client V2

/**
 * FHT.Statistics.Interfaces.Dto.Common.NetworkType, FHT.Statistics.Interfaces, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */

/**
* FHT.Statistics.Interfaces.Dto.Common.NetworkType, FHT.Statistics.Interfaces, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export var NetworkTypeEnum;

(function (NetworkTypeEnum) {
  NetworkTypeEnum["Disconnected"] = "Disconnected";
  NetworkTypeEnum["Wifi"] = "Wifi";
  NetworkTypeEnum["_2G"] = "_2G";
  NetworkTypeEnum["_3G"] = "_3G";
  NetworkTypeEnum["_4G"] = "_4G";
  NetworkTypeEnum["_5G"] = "_5G";
  NetworkTypeEnum["Cable"] = "Cable";
})(NetworkTypeEnum || (NetworkTypeEnum = {}));