import "core-js/modules/es.parse-float.js";
import "core-js/modules/es.date.to-string.js";
import { assert } from "@/common/assert";
import { fhtStorage } from "@/pages/base/fhtStorage";
$(function () {
  $(".fht-notice").each(function (_index, el) {
    var $fhtNotice = $(el);
    var isIgnorable = $fhtNotice.hasClass("fht-notice--ignorable"); // eslint-disable-next-line no-restricted-syntax

    var noticeKey = $fhtNotice.data("notice-key");

    if (isIgnorable) {
      assert(noticeKey, "data-notice-key must be defined for .fht-notice--ignorable");
      var ignorableIntervalSecond_1 = parseFloat($fhtNotice.data("ignorable-interval-second")); // string of seconds

      assert(ignorableIntervalSecond_1 > 0, "data-ignorable-interval-second must be defined and positive for .fht-notice--ignorable");
      fhtStorage.getItem(noticeKey).then(function (lastIgnoredTimeMsString) {
        var lastIgnoredTimeMs = lastIgnoredTimeMsString ? parseFloat(lastIgnoredTimeMsString) : undefined;

        if (lastIgnoredTimeMs) {
          var exceedsIgnorableInterval = new Date().getTime() >= lastIgnoredTimeMs + ignorableIntervalSecond_1 * 1000;

          if (exceedsIgnorableInterval) {
            $fhtNotice.fadeIn(500);
          } else {//stays in display:none state as is set in css
          }
        } else {
          $fhtNotice.fadeIn(500);
        }
      });
    }
  });
  /*改成这样是因为在产品搜索和服务搜索页面的这个关闭按钮监听不到事件*/

  $(document).on("click", ".fht-notice__close", function () {
    var $fhtNotice = $(this).closest(".fht-notice"); // eslint-disable-next-line no-restricted-syntax

    var noticeKey = $fhtNotice.data("notice-key");
    var isIgnorable = $fhtNotice.hasClass("fht-notice--ignorable");
    $fhtNotice.fadeOut(500);

    if (isIgnorable) {
      var nowMs = new Date().getTime(); //float of ms

      fhtStorage.setItem(noticeKey, nowMs + "");
    }
  });
});