import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.map.js";
import { SearchTypeEnum } from "@/apis/model/SearchTypeEnum";
import { assert } from "@/common/assert";
import { fhtStorage } from "@/pages/base/fhtStorage"; //追加历史记录

export function appendSearchHistory(searchType, q) {
  var MAX_HISTORY = 10;
  assert(SearchTypeEnum[searchType], "searchType invalid");
  var typeKey = "search_history_" + searchType;
  fhtStorage.getItem(typeKey).then(function (localHistoryStr) {
    var localHistoryList = [];

    if (localHistoryStr) {
      localHistoryList = JSON.parse(localHistoryStr);

      if (localHistoryList.indexOf(q) > -1) {
        var foundIndex = localHistoryList.indexOf(q);

        if (foundIndex > -1) {
          localHistoryList.splice(foundIndex, 1);
          localHistoryList.unshift(q);
        }
      } else {
        if (localHistoryList.length >= MAX_HISTORY) {
          localHistoryList.pop();
        }

        localHistoryList.unshift(q);
      }
    } else {
      localHistoryList.unshift(q);
    }

    fhtStorage.setItem(typeKey, JSON.stringify(localHistoryList));
  });
}

function parseSearchHistory(raw) {
  if (raw) {
    return JSON.parse(raw);
  }

  return [];
}

export function handleEmptySearch(typeKey) {
  return fhtStorage.getItem(typeKey).then(function (raw) {
    var searchHistoryList = parseSearchHistory(raw);

    if (searchHistoryList.length) {
      var options = $.map(searchHistoryList, function (item) {
        return {
          label: "<span class=\"search-box__search-item\">" + item + "</span>",
          value: item
        };
      });
      return options;
    } else {
      return [];
    }
  });
}