export var CustomJqueryEvents;

(function (CustomJqueryEvents) {
  CustomJqueryEvents["ActionableBatchIdsEvent"] = "actionableBatchIds.fht360";
  CustomJqueryEvents["AjaxLoadedHtmlEvent"] = "ajaxLoadedHtml.fht360";
  /** 在CMS左侧配置的里面改变模块组里面的模块的时候，
   * 右侧的预览iframe里面的模板页面需要做及时更新（也就是不刷新页面，局部更新)，
   * 有一些使用了jquery插件的模块就需要重新初始化
   * 此时，就可以注册这个事件*/

  CustomJqueryEvents["CmsModuleContentUpdatedEvent"] = "cmsModuleContentUpdated.fht360";
  CustomJqueryEvents["TopbarFixedEvent"] = "topbarFixed.fht360";
  CustomJqueryEvents["TopbarUnfixedEvent"] = "topbarUnfixed.fht360";
  CustomJqueryEvents["ScrollEvent"] = "scroll.fht360";
  CustomJqueryEvents["SuccessfullyLoggedInEvent"] = "successfullyLoggedIn.fht360";
  CustomJqueryEvents["ValidationResultChangedEvent"] = "validationResultChanged.fht360";
})(CustomJqueryEvents || (CustomJqueryEvents = {}));