// FHT Swagger jQuery client V2

/**
 * FHT.Statistics.Interfaces.Dto.Common.UserActivityType, FHT.Statistics.Interfaces, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */

/**
* FHT.Statistics.Interfaces.Dto.Common.UserActivityType, FHT.Statistics.Interfaces, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export var UserActivityTypeEnum;

(function (UserActivityTypeEnum) {
  UserActivityTypeEnum["EnterApp"] = "EnterApp";
  UserActivityTypeEnum["Register"] = "Register";
  UserActivityTypeEnum["Login"] = "Login";
  UserActivityTypeEnum["CreateCompany"] = "CreateCompany";
  UserActivityTypeEnum["Create"] = "Create";
  UserActivityTypeEnum["Update"] = "Update";
  UserActivityTypeEnum["ViewDetail"] = "ViewDetail";
  UserActivityTypeEnum["Impression"] = "Impression";
  UserActivityTypeEnum["ViewList"] = "ViewList";
  UserActivityTypeEnum["Search"] = "Search";
  UserActivityTypeEnum["Hover"] = "Hover";
  UserActivityTypeEnum["Click"] = "Click";
  UserActivityTypeEnum["Collect"] = "Collect";
  UserActivityTypeEnum["Report"] = "Report";
  UserActivityTypeEnum["Chat"] = "Chat";
  UserActivityTypeEnum["Forward"] = "Forward";
  UserActivityTypeEnum["FriendRequest"] = "FriendRequest";
  UserActivityTypeEnum["AcceptFriend"] = "AcceptFriend";
  UserActivityTypeEnum["RejectFriend"] = "RejectFriend";
  UserActivityTypeEnum["Error"] = "Error";
  UserActivityTypeEnum["AuthenticateCompany"] = "AuthenticateCompany";
  UserActivityTypeEnum["EnableShop"] = "EnableShop";
  UserActivityTypeEnum["CompanyManagement"] = "CompanyManagement";
  UserActivityTypeEnum["JoinCompany"] = "JoinCompany";
  UserActivityTypeEnum["SwitchTemplate"] = "SwitchTemplate";
})(UserActivityTypeEnum || (UserActivityTypeEnum = {}));