// FHT Swagger jQuery client V2

/**
 * FHT.Common.Types.DeviceType, FHT.Common.Types, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 */

/**
* FHT.Common.Types.DeviceType, FHT.Common.Types, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export var DeviceTypeEnum;

(function (DeviceTypeEnum) {
  DeviceTypeEnum["Web"] = "Web";
  DeviceTypeEnum["Ios"] = "Ios";
  DeviceTypeEnum["Android"] = "Android";
  DeviceTypeEnum["MobileWeb"] = "MobileWeb";
  DeviceTypeEnum["Other"] = "Other";
})(DeviceTypeEnum || (DeviceTypeEnum = {}));