import * as Sentry from "@sentry/browser";
var IS_DEV = process.env.NODE_ENV === "development";
export function assert(condition, message, tag) {
  if (!condition) {
    try {
      var errorMessage = message || "assert " + condition + " failed";
      throw new Error(errorMessage);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);

      if (IS_DEV) {
        throw err;
      }
    }
  }
}