import { DeviceTypeEnum } from "./DeviceTypeEnum";
export function convertFromDeviceTypeEnum(e) {
  switch (e) {
    case DeviceTypeEnum.Web:
      return 0;

    case DeviceTypeEnum.Ios:
      return 1;

    case DeviceTypeEnum.Android:
      return 2;

    case DeviceTypeEnum.MobileWeb:
      return 3;

    case DeviceTypeEnum.Other:
      return 255;

    default:
      throw new Error(e + " is an invalid DeviceTypeEnum");
  }
}