var ex = window.exceptionless;
if (!ex) throw new Error("window.exceptionless falsy"); //see https://www.npmjs.com/package/exceptionless

var serverUrl = "https://exceptionless.fht360.cn";
var apiKey = "a3ZqrAlzfd6ExZJo2CRogOQaXkyJVZZiJHdFQeVE";
var client = new ex.ExceptionlessClient({
  apiKey: apiKey,
  serverUrl: serverUrl,
  submissionBatchSize: 100
});
export var exceptionless = client;