import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/web.timers.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import { fhtCommonEnv } from "@/common/fhtGlobalEnv";
import { UrlHelperExtensions } from "@/url_helpers";
import { functionAlert } from "@/pages/modules/alerts";
import parse from "url-parse";
import { basicAccountInfoManager } from "@/pages/base/basicAccountInfoManager";
import { cmsHomeUrl } from "@/urls";
import "../topbar/loginCard.scss";
export function loadFht1OrFht2LoginInfoCard(ajaxUrl, params) {
  var $signIn = $(".top-bar__account--login");
  $.ajax({
    type: "GET",
    url: ajaxUrl,
    cache: false,
    data: params,
    success: function success(data) {
      return __awaiter(this, void 0, void 0, function () {
        var $response, $topAccount, $userInfo, info, $enterpriseCenterTab, $enterpriseCenterTabOptions, $cmsHomeLink, $companyCenter, $userCenterTab, $notificationTopBar;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              $response = $("<div />").html(data);
              $topAccount = $response.find("#top-bar__info");
              $userInfo = $response.find("#user-info");
              $("#userInfoContainer").html($userInfo.html());
              $(".top-bar__account").html($topAccount.html());
              $(".top-bar__notifications").fadeIn();
              return [4
              /*yield*/
              , basicAccountInfoManager.getBasicAccountInfo()];

            case 1:
              info = _a.sent();
              $enterpriseCenterTab = $(".top-bar-nav__item-enterprise-center"); //正常2.0账号登录

              if (info.UserId > 0) {
                if (info.CompanyId) {
                  $enterpriseCenterTabOptions = $enterpriseCenterTab.find(".top-bar-nav__second-level");
                  $cmsHomeLink = $enterpriseCenterTabOptions.children().last().clone();
                  $cmsHomeLink.find("a").attr("href", cmsHomeUrl(info.CompanyId)).text("我的商铺");
                  $cmsHomeLink.appendTo($enterpriseCenterTabOptions);
                  $enterpriseCenterTab.show();
                } else {
                  $enterpriseCenterTab.remove();
                }
              } else {
                $companyCenter = $response.find("#companyCenter");
                $userCenterTab = $(".top-bar-nav__item-user-center");
                $notificationTopBar = $(".top-bar__notifications");
                $userCenterTab.after($companyCenter);
                $userCenterTab.hide();
                $notificationTopBar.remove();
                $enterpriseCenterTab.remove();
              }

              setTimeout(function () {
                $(".user-info-login").fadeIn();
              }, 0);
              return [2
              /*return*/
              ];
          }
        });
      });
    },
    error: function error(_xhr, _textStatus, _thrownError) {
      $signIn.fadeIn();
    }
  });
}
export function loadFht1LoginInfoCard() {
  return __awaiter(this, void 0, void 0, function () {
    var info;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , basicAccountInfoManager.getBasicAccountInfo()];

        case 1:
          info = _a.sent();

          if (!info || info.UserId <= 0) {
            $.ajax({
              // eslint-disable-next-line fht-eslint-rules/string-literal-validator
              url: fhtCommonEnv.Hosts.FhtWebUrlRoot + "/Web/Account/CheckLoginStatus",
              dataType: "jsonp",
              suppressErrors: true,
              success: function success(result) {
                if (result.IsAuthenticated) {
                  $(document).data("isFht1Authenticated", result.IsAuthenticated); //登录页面跳转

                  var isInLoginPage = location.pathname.toLowerCase() === parse(UrlHelperExtensions.$LoginUrl()).pathname.toLowerCase();

                  if (isInLoginPage) {
                    // eslint-disable-next-line fht-eslint-rules/string-literal-validator
                    window.location.href = "/web/WeiBo/Company/EnterpriseCenter";
                  } //登录信息卡


                  loadFht1OrFht2LoginInfoCard(UrlHelperExtensions.$Fht1LoggedInUserIndexPartialUrl, result); //隐藏个人中心菜单

                  $(".__item-user-center").hide(); //设置免费发布按钮提醒fht1.0会员用户

                  $(".js-open-topicpost-modal").each(function () {
                    $(this).removeClass("js-open-topicpost-modal").addClass("js-publish-no-fht1authority");
                  }); //设置产品或服务收藏按钮提醒fht1.0会员用户

                  $(".js-collect").each(function () {
                    $(this).removeClass("js-collect").addClass("js-collect-fht1authority");
                  });
                  $(document).on("click", ".js-collect-fht1authority", function () {
                    functionAlert("老用户先迁移到2.0平台，才能收藏操作", function () {
                      return;
                    });
                  });
                  $(document).on("click", ".js-publish-no-fht1authority", function () {
                    functionAlert("老用户先迁移到2.0平台，才能发布信息", function () {
                      return;
                    });
                  });
                }
              },
              error: function error(_jqXHR, _textStatus, errorThrown) {
                console.error(errorThrown);
              }
            });
          }

          return [2
          /*return*/
          ];
      }
    });
  });
}