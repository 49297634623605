import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.to-string.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
      m = s && o[s],
      i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function next() {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};

import { UrlUtils } from "./urlUtils";
import { fhtCommonEnv } from "@/common/fhtGlobalEnv";
import { createLiveQuery } from "../vendors/vendor.livequery";
import { deviceId } from "./deviceId";
import session from "./webSessionCounter";
import "jquery-inview";
import platform from "platform";
import { basicAccountInfoManager } from "./basicAccountInfoManager";
import jqueryDataUtil from "@/common/jqueryDataUtil";
import { assert } from "@/common/assert";
import { BatchProcessor } from "./batchProcess";
import "jquery.cookie";
import { convertFromDeviceTypeEnum } from "@/apis/model/convertFromDeviceTypeEnum";
import { convertFromNetworkTypeEnum } from "@/apis/model/convertFromNetworkTypeEnum";
import { convertFromUserActivityTypeEnum } from "@/apis/model/convertFromUserActivityTypeEnum";
import { DeviceTypeEnum } from "@/apis/model/DeviceTypeEnum";
import { NetworkTypeEnum } from "@/apis/model/NetworkTypeEnum";
import { UserActivityTypeEnum } from "@/apis/model/UserActivityTypeEnum";
import { AppEntryTypeEnum } from "@/apis/model/AppEntryTypeEnum";

function getSessionId() {
  return __awaiter(this, void 0, Promise, function () {
    var _a;

    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          return [4
          /*yield*/
          , session.getLastActive()];

        case 1:
          _a = _b.sent().getTime() + "_";
          return [4
          /*yield*/
          , session.getCount()];

        case 2:
          return [2
          /*return*/
          , _a + _b.sent()];
      }
    });
  });
}

getSessionId().then(function (ssid) {
  $.cookie("ssid", ssid, {
    expires: 1
  });
});
var impressionCode = convertFromUserActivityTypeEnum(UserActivityTypeEnum.Impression);
var enterAppCode = convertFromUserActivityTypeEnum(UserActivityTypeEnum.EnterApp);
var basicStatInfoKeyDef = {
  DeviceId: true,
  SessionId: true,
  TrackingKey: true,
  UserId: true,
  UserCompanyId: true,
  DeviceType: true,
  Longitude: true,
  Latitude: true,
  UserAgent: true,
  Timestamp: true,
  AppVersion: true,
  Manufacturer: true,
  Model: true,
  OS: true,
  ScreenHeight: true,
  ScreenWidth: true,
  Network: true,
  Battery: true,
  Url: true,
  Referer: true,
  PageId: true,
  PreviousPageId: true
};
var statExtensionModelKeyDef = {
  ActivityType: true,
  ErrorCode: undefined,
  ErrorMsg: true
};
var commonKeys = new Set(Object.keys(basicStatInfoKeyDef).concat(Object.keys(statExtensionModelKeyDef)));

(function () {
  var skipLogging = fhtCommonEnv.DEBUG || fhtCommonEnv.AppEnv === "Local" || fhtCommonEnv.AppEnv === "Development";

  if (skipLogging) {
    console.log("will not push stats to aliyun in local & development env");
  }

  var project = fhtCommonEnv.AppEnv !== "Production" ? "fht360-log-development" : "fht360-log-production";
  var logstore = "web-tracking-" + fhtCommonEnv.AppEnv.toLowerCase();
  var host = "cn-hangzhou.log.aliyuncs.com"; // eslint-disable-next-line fht-eslint-rules/string-literal-validator

  var ajaxUrl = "https://" + project + "." + host + "/logstores/" + logstore + "/track?APIVersion=0.6.0";

  function logStatToAliyun(stat) {
    if (!skipLogging) {
      $.get(ajaxUrl, stat);
    } else {
      console.log("skip logging", stat);
    }
  }

  createLiveQuery($(".js-fht-stat"), function () {
    var statRoot = this;
    var contextKey = jqueryDataUtil.optionalString($(statRoot), "stat_context-key");
    if (!contextKey) return;

    function updateHref(a, contextKey) {
      // eslint-disable-next-line fht-eslint-rules/function-argument-validator
      if (a && a.href && a.href.indexOf("javascript") < 0) {
        a.href = UrlUtils.UpdateQueryString("stat_ctx", contextKey, a.href);
      }
    }

    if (contextKey && contextKey.length > 0) {
      if (statRoot.tagName === "A") {
        updateHref(statRoot, contextKey);
      } else {
        $("a", this).each(function (_index, el) {
          var $el = $(el);
          var isImmediateStatRoot = $el.closest(".js-fht-stat").get(0) === statRoot;

          if (isImmediateStatRoot) {
            updateHref(el, contextKey);
          }
        });
      }
    }
  }); //courtesy of https://stackoverflow.com/questions/54651201/how-do-i-covert-kebab-case-into-pascalcase?noredirect=1

  function clearAndUpper(text) {
    return text.replace(/-/, "").toUpperCase();
  }

  function toPascalCase(text) {
    return text.replace(/(^\w|-\w)/g, clearAndUpper);
  }

  function toNetwork(type) {
    if (type === "slow-2g" || type === "2g") return NetworkTypeEnum._2G;
    if (type === "3g") return NetworkTypeEnum._3G;
    if (type === "4g") return NetworkTypeEnum._4G;
    return;
  }

  var batcher = new BatchProcessor(function (items) {
    console.log("process batch of size: ", items.length);
    var impressions = items;
    var first = impressions[0];
    assert(first, "first falsy"); //move all other impression fields into first and only submit first

    var impressionItems = [];
    impressions.forEach(function (item) {
      impressionItems.concat(item.Impressions);
    });
    first.Impressions = impressionItems;
    logStatToAliyun(first);
  });

  function collectStat(info, ext) {
    var _a;

    return __awaiter(this, void 0, Promise, function () {
      var nav, connection, networkType, nt, stat, key, val, props, _b, _c, key;

      var _d, e_1, _e;

      return __generator(this, function (_f) {
        switch (_f.label) {
          case 0:
            nav = window.navigator;
            connection = nav.connection || nav.mozConnection || nav.webkitConnection;
            networkType = connection === null || connection === void 0 ? void 0 : connection.effectiveType;
            nt = toNetwork(networkType);
            _d = {};
            return [4
            /*yield*/
            , deviceId];

          case 1:
            _d.DeviceId = _f.sent();
            return [4
            /*yield*/
            , getSessionId()];

          case 2:
            stat = (_d.SessionId = _f.sent(), _d.UserId = info.UserId, _d.Url = location.href, _d.Referer = document.referrer, _d.Timestamp = +new Date(), _d.DeviceType = convertFromDeviceTypeEnum(DeviceTypeEnum.Web), _d.AppVersion = fhtCommonEnv.AppVersion, _d.Manufacturer = platform.manufacturer, _d.Model = platform.version, _d.OS = (_a = platform.os) === null || _a === void 0 ? void 0 : _a.family, _d.ScreenHeight = $(window).height(), _d.ScreenWidth = $(window).width(), _d.Network = nt ? convertFromNetworkTypeEnum(nt) : undefined, // eslint-disable-next-line compat/compat
            _d.UserAgent = navigator.userAgent, _d.UserCompanyId = info.CompanyId, _d.ErrorMsg = undefined, _d.ErrorCode = undefined, _d.TrackingKey = undefined, _d.Battery = undefined, _d.Latitude = undefined, _d.Longitude = undefined, _d.PageId = undefined, _d.PreviousPageId = undefined, _d);

            for (key in stat) {
              val = stat[key];

              if (typeof val === "undefined") {
                delete stat[key];
              }
            }

            props = {};
            Object.assign(props, ext);

            try {
              for (_b = __values(Object.keys(props)), _c = _b.next(); !_c.done; _c = _b.next()) {
                key = _c.value;

                if (commonKeys.has(key)) {
                  stat[key] = props[key];
                  delete props[key];
                }
              }
            } catch (e_1_1) {
              e_1 = {
                error: e_1_1
              };
            } finally {
              try {
                if (_c && !_c.done && (_e = _b["return"])) _e.call(_b);
              } finally {
                if (e_1) throw e_1.error;
              }
            } //put all subclass properties into a Ext field.


            stat["Ext"] = JSON.stringify(props);
            return [2
            /*return*/
            , stat];
        }
      });
    });
  }

  function onVisible() {
    return __awaiter(this, void 0, void 0, function () {
      var info;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , basicAccountInfoManager.getBasicAccountInfo()];

          case 1:
            info = _a.sent();
            $(".js-fht-stat").one("inview", function (event, isInView) {
              return __awaiter(this, void 0, void 0, function () {
                var dataAttrs, ext, key, propName, value, stat;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      dataAttrs = $(event.target).data();
                      ext = {};

                      for (key in dataAttrs) {
                        if (key.indexOf("stat_") === 0) {
                          propName = toPascalCase(key.substring(5));
                          value = dataAttrs[key];

                          if (typeof value !== "undefined") {
                            ext[propName] = dataAttrs[key];
                          }
                        }
                      }

                      return [4
                      /*yield*/
                      , collectStat(info, ext)];

                    case 1:
                      stat = _a.sent();

                      if (isInView) {
                        if (stat.ActivityType === impressionCode) {
                          //buffer for Impression because of its large volumn.
                          batcher.queue(stat);
                        } else {
                          logStatToAliyun(stat);
                        }
                      }

                      return [2
                      /*return*/
                      ];
                  }
                });
              });
            });
            return [2
            /*return*/
            ];
        }
      });
    });
  }

  function logEnterApp() {
    return __awaiter(this, void 0, void 0, function () {
      var ENTER_APP_KEY, loadingMs, info, ext, stat;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            ENTER_APP_KEY = "_enter_app_";
            if (!!sessionStorage.getItem(ENTER_APP_KEY)) return [3
            /*break*/
            , 3];
            loadingMs = typeof window.performance !== "undefined" ? window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart : undefined;
            return [4
            /*yield*/
            , basicAccountInfoManager.getBasicAccountInfo()];

          case 1:
            info = _a.sent();
            ext = {
              ActivityType: enterAppCode,
              Entry: AppEntryTypeEnum.Fresh,
              LoadingMs: loadingMs
            };
            return [4
            /*yield*/
            , collectStat(info, ext)];

          case 2:
            stat = _a.sent();
            logStatToAliyun(stat);
            _a.label = 3;

          case 3:
            sessionStorage.setItem(ENTER_APP_KEY, new Date().toString());
            return [2
            /*return*/
            ];
        }
      });
    });
  }

  logEnterApp();
  /* eslint-disable-next-line compat/compat */

  if (document.hidden) {
    document.addEventListener("visibilitychange", onVisible, false);
  } else {
    onVisible();
  }
})();