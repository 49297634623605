import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.find.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import "./index.scss";
import "./loginCard.scss"; // import { mta } from "@/pages/base/mta";

import { UrlHelperExtensions } from "@/url_helpers";
import { fhtCommonEnv, isOnPremisesCms } from "@/common/fhtGlobalEnv";
import { basicAccountInfoManager } from "@/pages/base/basicAccountInfoManager";
import { loadFht1LoginInfoCard, loadFht1OrFht2LoginInfoCard } from "@/pages/modules/fht1LoginCard";
import { AccountApiApi } from "@/apis/api/AccountApiApi";
import { ApplicationEnvEnum } from "@/apis/model/ApplicationEnvEnum";
$(function () {
  $(document).on("mouseenter", ".top-bar__account", function () {
    $(".js-login-card").stop().slideDown(300);
    $(this).find(".top-bar__name-icon").css({
      transform: "rotate(180deg)"
    });
  }).on("mouseleave", ".top-bar__account", function () {
    $(".js-login-card").stop().slideUp(100);
    $(this).find(".top-bar__name-icon").css({
      transform: "rotate(0deg)"
    });
  });
});
$(function () {
  return __awaiter(this, void 0, void 0, function () {
    function setupTopbar() {
      $(document).on("mouseenter mouseleave", ".top-bar-nav__item", function (ev) {
        var isHovering = ev.type === "mouseenter";
        var $this = $(this);
        var $ul = $this.find("ul").stop();

        if (isHovering) {
          $ul.slideDown(300);
        } else {
          $ul.slideUp(100);
        }
      });
    }

    function loadLogInCard() {
      if (!isOnPremisesCms) {
        loadFht1OrFht2LoginInfoCard(UrlHelperExtensions.LoggedInUserIndexPartialUrl);
      }
    }

    var info;

    var _this = this;

    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          setupTopbar();
          $(document).on("click", "#nav-logout", function () {
            return __awaiter(_this, void 0, void 0, function () {
              var info_1;
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    _a.trys.push([0,, 3, 4]);

                    return [4
                    /*yield*/
                    , basicAccountInfoManager.getBasicAccountInfo()];

                  case 1:
                    info_1 = _a.sent();

                    if (fhtCommonEnv.AppEnv !== ApplicationEnvEnum.Local && info_1.IsOldVipInFht2) {
                      $.ajax({
                        // eslint-disable-next-line fht-eslint-rules/string-literal-validator
                        url: fhtCommonEnv.Hosts.FhtWebUrlRoot + "/web/Account/LoginOff",
                        data: {},
                        dataType: "jsonp",
                        suppressErrors: true,
                        success: function success(_data, _status) {// location.reload();
                        },
                        error: function error(_jqXHR, _textStatus, errorThrown) {
                          console.error(errorThrown);
                        }
                      });
                    }

                    return [4
                    /*yield*/
                    , new AccountApiApi().accountApiLogOff()];

                  case 2:
                    _a.sent();

                    return [3
                    /*break*/
                    , 4];

                  case 3:
                    location.reload();
                    return [7
                    /*endfinally*/
                    ];

                  case 4:
                    return [2
                    /*return*/
                    ];
                }
              });
            });
          });
          $(document).on("click", ".login-card-old__quit", function () {
            $.ajax({
              // eslint-disable-next-line fht-eslint-rules/string-literal-validator
              url: fhtCommonEnv.Hosts.FhtWebUrlRoot + "/web/Account/LoginOff",
              data: {},
              dataType: "jsonp",
              suppressErrors: true,
              success: function success(_data, _status) {
                location.reload();
              },
              error: function error(_jqXHR, _textStatus, errorThrown) {
                console.error(errorThrown);
              }
            });
          });
          return [4
          /*yield*/
          , basicAccountInfoManager.getBasicAccountInfo()];

        case 1:
          info = _a.sent();

          if (info.UserId > 0) {
            loadLogInCard();
          } else {
            loadFht1LoginInfoCard();
            basicAccountInfoManager.onAccountInfoUpdated(loadLogInCard);
          }

          return [2
          /*return*/
          ];
      }
    });
  });
});