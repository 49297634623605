// FHT Swagger jQuery client V2

/**
 * FHT.Statistics.Interfaces.InputModels.AppEntryType, FHT.Statistics.Interfaces, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 */

/**
* FHT.Statistics.Interfaces.InputModels.AppEntryType, FHT.Statistics.Interfaces, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
*/
// mark 1 model enum
export var AppEntryTypeEnum;

(function (AppEntryTypeEnum) {
  AppEntryTypeEnum["Fresh"] = "Fresh";
  AppEntryTypeEnum["Icon"] = "Icon";
  AppEntryTypeEnum["Chat"] = "Chat";
  AppEntryTypeEnum["Notification"] = "Notification";
})(AppEntryTypeEnum || (AppEntryTypeEnum = {}));