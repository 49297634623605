import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.find.js";
import "./index.scss";
var $breadcrumbCatgories = $(".fht-breadcrumb__category");

if ($breadcrumbCatgories.length) {
  import("@/pages/vendors/vendor.hoverIntent").then(function (_a) {
    var createHoverIntent = _a.createHoverIntent;
    createHoverIntent($breadcrumbCatgories, {
      timeout: 200,
      over: function over() {
        $(this).find(".fht-breadcrumb__category-list").show();
      },
      out: function out() {
        $(this).find(".fht-breadcrumb__category-list").hide();
      }
    });
  });
}